import React from 'react';
import { Text, Heading } from '@chakra-ui/core';
import styled from 'styled-components';
import Banner from '../components/guide/Banner';
import Layout from '../components/skeleton/layout';
import SEO from '../components/skeleton/seo';
import { mediaQueries } from '../helpers';

const MainContent = styled.div`
  margin-top: 40px;
  min-height: 85vh;
  width: 100%;
  padding: 0px 54px;

  ${mediaQueries.mobile(`
    margin-top: 0;
    padding: 0px;
  `)}
`;

const TextWrapper = styled.div`
  padding: 24px 16px;
`;

const LegalPage = () => (
  <Layout headerChildren={<Banner />}>
    <SEO title="Mentions légales" />
    <MainContent>
      <Heading as="h1" textAlign="left" size="xl" color="gray.600">
        Mentions legales
      </Heading>

      <TextWrapper>
        <Text>
          Conformément aux dispositions des Articles 6-III et 19 de la Loi
          n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie
          numérique, dite L.C.E.N., il est porté à la connaissance des
          Utilisateurs du site lecocoondesarah.com les présentes mentions
          légales. La connexion et la navigation sur le site (indiquer le nom du
          site) par l’Utilisateur implique acceptation intégrale et sans réserve
          des présentes mentions légales. Ces dernières sont accessibles sur le
          site à la rubrique « Mentions légales ».
        </Text>
      </TextWrapper>

      <TextWrapper>
        <Heading as="h2" color="gray.600" size="md">
          ARTICLE 1 : L’ÉDITEUR
        </Heading>
        <Text>
          L'édition du site lecocoondesarah.com est assurée par la Société SARL
          FAST2D au capital de 600 euros, immatriculée au RCS de ANNECY sous le
          numéro 844 725 085 dont le siège social est situé au 94 ROUTE DES
          RIVES , 74400 CHAMONIX MONT-BLANC, numéro de téléphone 0618346418,
          adresse e-mail : fast2d@free.fr. N° de TVA intracommunautaire : AUCUN
          Le Directeur de la publication est FAST2D
        </Text>
      </TextWrapper>

      <TextWrapper>
        <Heading as="h2" color="gray.600" size="md">
          ARTICLE 2 : L’HÉBERGEUR
        </Heading>
        <Text>
          L'hébergeur du site lecocoondesarah.com est la Société netlify, dont
          le siège social est situé au 610 22nd Street, Suite 315 CA 94107 San
          Francisco , avec le numéro de téléphone : +1 844-899-7312.
        </Text>
      </TextWrapper>

      <TextWrapper>
        <Heading as="h2" color="gray.600" size="md">
          ARTICLE 3 : ACCÈS AU SITE
        </Heading>
        <Text>
          Le site est accessible par tout endroit, 7j/7, 24h/24 sauf cas de
          force majeure, interruption programmée ou non et pouvant découlant
          d’une nécessité de maintenance. En cas de modification, interruption
          ou suspension des services le site lecocoondesarah.com ne saurait être
          tenu responsable.
        </Text>
      </TextWrapper>

      <TextWrapper>
        <Heading as="h2" color="gray.600" size="md">
          ARTICLE 4 : COLLECTE DES DONNÉES
        </Heading>
        <Text>
          Le site est exempté de déclaration à la Commission Nationale
          Informatique et Libertés (CNIL) dans la mesure où il ne collecte
          aucune donnée concernant les utilisateurs.
        </Text>
      </TextWrapper>

      <TextWrapper>
        <Heading as="h2" color="gray.600" size="md">
          ARTICLE 5 : COOKIES
        </Heading>
        <Text>
          L’Utilisateur est informé que lors de ses visites sur le site, un
          cookie peut s’installer automatiquement sur son logiciel de
          navigation. En naviguant sur le site, il les accepte. Un cookie est un
          élément qui ne permet pas d’identifier l’Utilisateur mais sert à
          enregistrer des informations relatives à la navigation de celui-ci sur
          le site Internet. L’Utilisateur pourra désactiver ce cookie par
          l’intermédiaire des paramètres figurant au sein de son logiciel de
          navigation.
        </Text>
      </TextWrapper>

      <TextWrapper>
        <Heading as="h2" color="gray.600" size="md">
          ARTICLE 6 : PROPRIÉTÉ INTELLECTUELLE
        </Heading>
        <Text>
          Toute utilisation, reproduction, diffusion, commercialisation,
          modification de toute ou partie du sitelecocoondesarah.com, sans
          autorisation de l’Editeur est prohibée et pourra entraînée des actions
          et poursuites judiciaires telles que notamment prévues par le Code de
          la propriété intellectuelle et le Code civil.
        </Text>
      </TextWrapper>
    </MainContent>
  </Layout>
);

export default LegalPage;
